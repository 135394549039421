// Fonts
@import 'font';

// Variables
@import 'variables';

// Bootstrap
@import 'bootstrap/scss/bootstrap';

.btn-primary:hover {
    color: #fff !important;
}
.btn-outline-primary:hover {
    color: #fff !important;
}

.card-header button[data-toggle="collapse"].collapsed::after {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f107";
    display: block;
    float: right;
}
.card-header button[data-toggle="collapse"]::after {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f106";
    display: block;
    float: right;
}

.top-0 {
    top: 0;
}
.bottom-0 {
    bottom: 0;
}
.left-0 {
    left: 0;
}
.right-0 {
    right: 0;
}

.object-fit-fill {
    object-fit: fill;
}
.object-fit-contain {
    object-fit: contain;
}
.object-fit-cover {
    object-fit: cover;
}
.object-fit-none {
    object-fit: none;
}
.object-fit-scale-down {
    object-fit: scale-down;
}
